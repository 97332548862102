.contenedorInicio {
  background-color: #ffffff;
}
.outer-div-Footer {
  background-color: red;
  width: 100vw;
  height: 15vh;
}
.form-container {
  max-width: 50%; /* Ancho máximo del formulario */
  margin: 0 auto; /* Margen superior e inferior de 20px y centrado horizontalmente */
  padding: 20px; /* Espaciado interno del formulario */
  border: 1px solid #ccc; /* Borde para visualizar el formulario (opcional) */
  border-radius: 8px; /* Esquinas redondeadas (opcional) */
  background-color: #f9f9f9; /* Color de fondo (opcional) */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra para el formulario (opcional) */
  margin-top: 2%;
}
.movement-form-container {
  max-width: 80%; /* Ancho máximo del formulario */
  margin: 0 auto; /* Margen superior e inferior de 20px y centrado horizontalmente */
  padding: 20px; /* Espaciado interno del formulario */
  border: 1px solid #ccc; /* Borde para visualizar el formulario (opcional) */
  border-radius: 8px; /* Esquinas redondeadas (opcional) */
  background-color: #f9f9f9; /* Color de fondo (opcional) */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra para el formulario (opcional) */
  margin-top: 2%;
}

form {
  display: flex; /* Usa flexbox para alinear los elementos */
  flex-direction: column; /* Coloca los elementos en columna */
}

input, textarea, select, button {
  margin: 10px 0; /* Margen entre los elementos del formulario */
  padding: 10px; /* Espaciado interno de los elementos */
  font-size: 16px; /* Tamaño de fuente */
  border: 1px solid #ccc; /* Borde de los inputs (opcional) */
  border-radius: 4px; /* Esquinas redondeadas de los inputs (opcional) */
}

button {
  cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
  background-color: #4CAF50; /* Color de fondo del botón */
  color: white; /* Color del texto del botón */
  border: none; /* Sin borde */
}

button:hover {
  background-color: #45a049; /* Color de fondo del botón al pasar el mouse */
}

.title-container {
  max-width: 50%; /* Ancho máximo para el encabezado */
  margin: 0 auto; /* Centrado horizontalmente */
  margin-top: 2%; /* Margen superior */
}

